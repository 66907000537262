// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-template-404-js": () => import("./../../../src/template-404.js" /* webpackChunkName: "component---src-template-404-js" */),
  "component---src-template-aggregate-js": () => import("./../../../src/template-aggregate.js" /* webpackChunkName: "component---src-template-aggregate-js" */),
  "component---src-template-article-js": () => import("./../../../src/template-article.js" /* webpackChunkName: "component---src-template-article-js" */),
  "component---src-template-article-with-map-js": () => import("./../../../src/template-article-with-map.js" /* webpackChunkName: "component---src-template-article-with-map-js" */),
  "component---src-template-cbnrtop-10-js": () => import("./../../../src/template-cbnrtop10.js" /* webpackChunkName: "component---src-template-cbnrtop-10-js" */),
  "component---src-template-cityguide-js": () => import("./../../../src/template-cityguide.js" /* webpackChunkName: "component---src-template-cityguide-js" */),
  "component---src-template-homepage-js": () => import("./../../../src/template-homepage.js" /* webpackChunkName: "component---src-template-homepage-js" */),
  "component---src-template-mobilearticle-js": () => import("./../../../src/template-mobilearticle.js" /* webpackChunkName: "component---src-template-mobilearticle-js" */)
}

